import React from 'react';
import './App.css';

function App() {
  const siteName = 'Many Desks';
  document.title = siteName;
  return <div className="App">{siteName}</div>;
}

export default App;
